<template>
<v-row>
    <v-col cols="12">
      <base-card>
        <v-card-title>Remolque</v-card-title>
        <v-card-text>
            <v-row>
            <v-col cols="3">
                <v-text-field
                                v-model="remolque.no_economico"
                                label="No. Económico"
                                class="uppercase"
                                
                            /> 
            </v-col>
            <v-col cols="3">
<v-text-field
                                v-model="remolque.marca"
                                label="Marca"
                                class="uppercase"
                                
                            /> 
            </v-col>
                 <v-col cols="3">
<v-text-field
                                v-model="remolque.modelo"
                                label="Modelo"
                                class="uppercase"
                                
                            /> 
            </v-col>
              <v-col cols="3">
<v-text-field
                                v-model="remolque.anio"
                                label="Año"
                                class="uppercase"
                                
                            /> 
            </v-col>
            </v-row>
            <v-row>
                  <v-col cols="3">
<v-text-field
                                v-model="remolque.placas"
                                label="Placas"
                                class="uppercase"
                                
                            /> 
            </v-col>
            <v-col cols="3">
              
               <v-select
                      v-model="remolque.idtipo_remolque"
                      :items="lista_tipos"
                      item-text="descripcion"
                      item-value="idtipo_remolque"
                     
                    >

                    </v-select>

            </v-col>
            <v-col cols="2">
                 <v-btn @click.stop="showScheduleForm=true" color="primary"><v-icon>mdi-plus</v-icon>Agregar Tipo</v-btn>

            </v-col>
            </v-row>
            <v-row>
                  <v-col cols="4">
                          <v-file-input
  truncate-length="15"
  label="Tarjeta de Circulación"
   @change="onFileTCChange"
></v-file-input>
                        </v-col>
          
 <v-col cols="4">
                          <v-file-input
  truncate-length="15"
  label="Tarjeta Verificación Físico Mecánica"
   @change="onFileFMChange"
></v-file-input>
                        </v-col>
            


            </v-row>
            <v-row>
                <v-col cols="4">
                          <v-file-input
  truncate-length="15"
  label="Póliza de Seguro"
   @change="onFilePolizaChange"
></v-file-input>
                        </v-col>  
                        <v-col cols="4">
                          <v-file-input
  truncate-length="15"
  label="Factura"
   @change="onFileFactChange"
></v-file-input>
                        </v-col>  
            </v-row>
            <v-row>
              <v-col cols="4">
                <v-btn color="success" @click="saveremolque">
                     <v-icon>mdi-content-save-outline</v-icon>
                  Guardar
                </v-btn>
              </v-col>
              </v-row>
        </v-card-text>
      </base-card>
    </v-col>
             <modalTipoRemolque v-model="showScheduleForm" />

</v-row>
</template>
<script>
import { url } from "../../main.js";
import moment from "moment-timezone";
import modalTipoRemolque from "./ModalTipoRemolque.vue"

export default {
      components: { modalTipoRemolque},

   metaInfo: {
      // title will be injected into parent titleTemplate
      title: "Remolque"
    },
    data () {
      return {
                     showScheduleForm: false,

          enviar: {},
        idremolque: this.$route.params.idremolque,
          edit:false,
               remolque: {
        idremolque: 0,
        idtipo_remolque: 0,
        no_economico: "",
        marca: "",
        modelo: "",
        anio: "",
        placas: "",
        idtipo_remolque: 0,

        tarjeta_circulacion: null,
        tarjeta_fm: null,
        tarjeta_emision: null,
        factura: null,
        seguro: null,
      },
 lista_tipos: []
      }
    },
          mounted(){
  this.vertipos();
this.permiso = localStorage.getItem("permission");
    console.log(this.idoperador)
    if(this.idremolque==0)
    {


    }else{
    this.editremolque();
   
    }
    },
    methods:{
        editremolque() {
    
      this.axios
        .get(url + "apiRemolques.php/remolque/" + this.idremolque)
        .then((respuesta) => {
          console.log(respuesta);
         this.remolque=respuesta.data;
                          

          this.edit = true;
        });
    }, vertipos() {
      this.axios
        .get(url + "apiRemolques.php/tipos_remolque")
        .then((respuesta) => {
          console.log(respuesta);
          this.lista_tipos = respuesta.data;

          console.log(this.lista_tipos);
         
           //   this.remolque.idtipo_remolque = this.lista_tipos[0].idtipo_remolque;
          
         
        })
        .catch(function () {
          console.log("FAILURE ver!!");
        });
    }, handleChangeU(e) {
    
    },
        onFileFactChange(e) {
      this.remolque.factura = e.target.files[0];
      console.log(e.target.files);
    },
    onFileFMChange(e) {
      console.log(e);

      this.remolque.tarjeta_fm = e.target.files[0];
      //this.uploadFile(this.operador.documentacion.ine)
      console.log(e.target.files);
    },
    onFileTVChange(e) {
      console.log(e);

      this.remolque.tarjeta_emision = e.target.files[0];
      //this.uploadFile(this.operador.documentacion.ine)
      console.log(e.target.files);
    },
    onFilePolizaChange(e) {
      console.log(e);

      this.remolque.seguro = e.target.files[0];
      //this.uploadFile(this.operador.documentacion.ine)
      console.log(e.target.files);
    },
    onFileTCChange(e) {
      console.log(e);

      this.remolque.tarjeta_circulacion = e.target.files[0];
      //this.uploadFile(this.operador.documentacion.ine)
      console.log(e.target.files);
    },
    uploadFileFM(file, idtracto) {
      console.log("upload");
      var formData = new FormData();
      formData.append("idtracto", idtracto);
      formData.append("tarjeta_fisico_mecanica", file);
      this.axios
        .post(
          url + "apiRemolques.php/uploadFM",
          formData,

          {
            headers: {
              "Content-Type": file.type,
            },
          }
        )
        .then((respuesta) => {
          console.log(respuesta);
          // this.file= null;
          this.verremolquees();
        })
        .catch((respuesta) => {
          console.log(respuesta);
        });
    },
    uploadFileTC(file, idtracto) {
      console.log("upload");
      var formData = new FormData();
      formData.append("idtracto", idtracto);
      formData.append("tarjeta_circulacion", file);
      this.axios
        .post(
          url + "apiRemolques.php/uploadTC",
          formData,

          {
            headers: {
              "Content-Type": file.type,
            },
          }
        )
        .then((respuesta) => {
          console.log(respuesta);
          // this.file= null;
          this.verremolquees();
        })
        .catch((respuesta) => {
          console.log(respuesta);
        });
    },

   
    uploadFileSeguro(file, idtracto) {
      console.log("upload");
      var formData = new FormData();
      formData.append("idtracto", idtracto);
      formData.append("seguro", file);
      this.axios
        .post(
          url + "apiRemolques.php/uploadSeguro",
          formData,

          {
            headers: {
              "Content-Type": file.type,
            },
          }
        )
        .then((respuesta) => {
          console.log(respuesta);
          // this.file= null;
          this.verremolquees();
        })
        .catch((respuesta) => {
          console.log(respuesta);
        });
    },
    uploadFileFactura(file, idtracto) {
      console.log("upload");
      var formData = new FormData();
      formData.append("idtracto", idtracto);
      formData.append("factura", file);
      this.axios
        .post(
          url + "apiRemolques.php/uploadFactura",
          formData,

          {
            headers: {
              "Content-Type": file.type,
            },
          }
        )
        .then((respuesta) => {
          console.log(respuesta);
          // this.file= null;
          this.verremolquees();
        })
        .catch((respuesta) => {
          console.log(respuesta);
        });
    }, 
        saveremolque() {
            console.log(this.validarcampos())
      if (this.validarcampos()) {
        if (this.remolque != null) {
          console.log(this.remolque);
          this.axios
            .post(url + "apiRemolques.php/saveRemolques", this.remolque)
            .then((respuesta) => {
              console.log(respuesta);
              if (respuesta.data != 0) {
                this.edit = false;
               this.limpiarcampos();

                this.verremolques();
                if (this.remolque.tarjeta_fm != null) {
                  console.log("xxx");
                  console.log(
                    this.uploadFileFM(this.remolque.tarjeta_fm, respuesta.data)
                  );
                }

                if (this.remolque.tarjeta_circulacion != null) {
                  console.log("xxx");
                  console.log(
                    this.uploadFileTC(
                      this.remolque.tarjeta_circulacion,
                      respuesta.data
                    )
                  );
                }
                if (this.remolque.tarjeta_emision != null) {
                  this.uploadFileEmision(
                    this.remolque.tarjeta_emision,
                    respuesta.data
                  );
                }

                if (this.remolque.seguro != null) {
                  this.uploadFileSeguro(this.remolque.seguro, respuesta.data);
                }

                if (this.remolque.factura != null) {
                  this.uploadFileFactura(this.remolque.factura, respuesta.data);
                }
              }
            })
            .catch(function () {
              console.log("FAILURE!!");
            });
        }
      } else {
        this.$swal(
          "ABC Remolques",
          "Debe ingresar todos los datos del remolque (Sólo documentación es opcional)",
          "warning"
        );
      }
    },
    selectlasttipo(){
      console.log('xxx');
  var size=this.lista_tipos.length;
          this.remolque.idtipo_remolque = this.lista_tipos[size-1].idtipo_remolque;
    },
    limpiarcampos() {
      this.remolque = {};

      //Limpiar campo de tarjeta de circulación
      const tcinput = this.$refs.tcinput;
      tcinput.type = "text";
      tcinput.type = "file";

      //Limpiar campo de tarjeta fisicomecánica

      const fminput = this.$refs.fminput;
      fminput.type = "text";
      fminput.type = "file";

      //Limpiar campo de póliza de seguro
      const polizainput = this.$refs.polizainput;
      polizainput.type = "text";
      polizainput.type = "file";

      //Limpiar campo de factura

      const facturainput = this.$refs.facturainput;
      facturainput.type = "text";
      facturainput.type = "file";
    },
    
   
    validarcampos() {
        console.log(this.remolque);
      if (
        this.remolque.no_economico == "" ||
        this.remolque.placas==""
        //||
      ) {
        return false;
      } else {
        return true;
      }
    },
    
    uploadFilelic(file, idoperador) {
      console.log("upload");
      var formData = new FormData();
      formData.append("idoperador", idoperador);
      formData.append("licencia", file);
      this.axios
        .post(
          url + "apiOperadores.php/uploadlicencia",
          formData,

          {
            headers: {
              "Content-Type": file.type,
            },
          }
        )
        .then((respuesta) => {
          console.log(respuesta);
          // this.file= null;
          this.veroperadores();
        })
        .catch((respuesta) => {
          console.log(respuesta);
        });
    },
    uploadFileExamen(file, idoperador) {
      console.log("upload");
      var formData = new FormData();
      formData.append("idoperador", idoperador);
      formData.append("examen_medico", file);
      this.axios
        .post(
          url + "apiOperadores.php/uploadexamen_medico",
          formData,

          {
            headers: {
              "Content-Type": file.type,
            },
          }
        )
        .then((respuesta) => {
          console.log(respuesta);
          // this.file= null;
          this.veroperadores();
        })
        .catch((respuesta) => {
          console.log(respuesta);
        });
    }
    }
      
    }
</script>
