<template>
    <v-dialog
   v-model="show" 
   persistent
   fullscreen
      hide-overlay
      transition="dialog-bottom-transition"

    >
    <v-card>
             <v-card-title>Tipos de Remolque</v-card-title>
 <v-divider></v-divider>
       
        <v-row>
           <v-col cols="6">
                      <v-text-field
                                 v-model="tipo_remolque.descripcion"
                                label="Descripción"
                                class="uppercase"
                                
                            />
                  </v-col>
           
         
         
            <v-col cols="4">
               <v-text-field
                                 v-model="search"
                                label="Búsqueda"
                                class="uppercase"
                                  append-icon="mdi-magnify"
                            />
            
            </v-col>
          </v-row>
                <v-data-table
                 locale="es"
                    :search="search"
                    :headers="headers"
                    :items="lista_tipo_remolque"
                    item-key="descripcion"
                    
                    class="elevation-1 table-one"
                    multi-sort
                >
             
              
                </v-data-table>

         
          <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
         @click="saveTipoRemolque" 
            color="success"
            text
            
          >
            Guardar
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="show = false"
          >
            Cerrar
          </v-btn>
        </v-card-actions>
    </v-card>
    </v-dialog>
</template>
<script>
import { url } from "../../main.js";

export default {
      props: {
           
            value: Boolean
        },
  data() {
    return {
      tipo_remolque: {
        idtipo_remolque: 0,
        descripcion: "",
      },
      lista_tipo_remolque:[],
      busqueda_tipo_remolques:"",
       currentSort: "",
      currentSortDir: "",
      pageSize: 10,
      currentPage: 1,
  
       search: '',
           // selected: [],
            headers: [
                {
                    text: 'Descripción',
                    align: 'start',
                    sortable: false,
                    value: 'descripcion'
                }
               
            ]
    };
  },
  name: "modal",  
  mounted() {
    console.log('xxx')
    this.getTiposRemolque();
  },
   computed: {
           show: {
      get () {
        return this.value
      },
      set (value) {
         this.$emit('input', value)
      }
    
  },
     
   },
  methods: {
    close() {
      this.$emit("close");
    },
    saveTipoRemolque() {
      if(this.validarcampos())
      {
      this.axios
        .post(url + "apiRemolques.php/tipos_remolque", this.tipo_remolque)
        .then((respuesta) => {
          console.log(respuesta);
          if(respuesta.data.status==200)
          {
            this.getTiposRemolque()
          }
        });
      }
    },
    getTiposRemolque() {
      this.axios
        .get(url + "apiRemolques.php/tipos_remolque")
        .then((respuesta) => {
         
          this.lista_tipo_remolque=respuesta.data
           console.log(this.lista_tipo_remolque)
        });
    },
    edittiporemolque(idtipo_remolque){

      console.log(idtipo_remolque);
      this.axios
        .get(url + "apiRemolques.php/tipo_remolque/" + idtipo_remolque)
        .then((respuesta) => {
          console.log(respuesta);
          this.tipo_remolque = respuesta.data;
          this.edit = true;
        });
    
    },
    eliminartiporemolque(tipo_remolque){
       this.$swal({
        title: "Confirmación",
        text: "¿Desea eliminar el tipo de remolque?",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Sí, eliminar",
      }).then((result) => {
        if (result.value) {
          this.$http
            .post(url + "apiRemolques.php/delete_tipo_remolque", tipo_remolque)
            .then((respuesta) => {
              console.log(respuesta);

              if (respuesta.data.status === 200) {
                this.$swal("Tipo de Remolque Eliminado");
                this.getTiposRemolque();
              }
            });
        }
      });

    },
    validarcampos() {
      if (
        this.tipo_remolque.descripcion == ""
        //||
        // this.remolque.telefono == ""
      ) {
        return false;
      } else {
        return true;
      }
    },close() {
      this.limpiarCampos();

    },
     limpiarCampos()
    {
      this.tipo_remolque.idtipo_remolque=0;
      this.tipo_remolque.descripcion="";
   

    }
    
  },
};
</script>
